<template>
  <div class="container_about">
    <div class="logo d-flex justify-center align-center pa-3">
      <img
        v-if="$isMobile()"
        src="../../../assets/img/logo_kesling_old.png"
        class="mr-3"
        height="70px"
        alt=""
      >

      <img
        v-else
        src="../../../assets/img/logo_kesling.png"
        class="mr-3"
        height="130px"
        alt=""
      >
    </div>

    <div
      v-if="content && $isMobile()"
      class="pa-5"
    >
      <v-card
        v-for="item in content"
        :key="item.id"
        class="rounded-max custom_card pa-5"
        style="margin-top: 20px"
      >
        <h2 class="mb-3 ">
          {{ item.judul }}
        </h2>
        <p
          v-if="item.konten !== 'null'"
          class="small_txt"
          v-html="item.konten"
        />
        <div v-if="item.diagram.length">
          <v-col
            class="d-flex"
            cols="12"
            sm="3"
          >
            <v-select
              v-model="diagram"
              :items="item.diagram"
              class="ma-0 pa-0"
              return-object
              item-text="jenis"
              dense
              outlined
            />
          </v-col>

          <div
            v-if="diagram.url_file"
            class="d-flex justify-center"
          >
            <img
              :src="diagram.url_file"
              height="150px"
              alt=""
            >
          </div>
        </div>

        <div v-if="item.faq.length">
          <v-expansion-panels
            accordion
            flat
          >
            <v-expansion-panel
              v-for="(x, i) in item.faq"
              :key="i"
              class="mb-1"
            >
              <v-expansion-panel-header
                style="display: inline !important;"
                expand-icon="none"
              >
                <div>
                  <v-icon
                    class="mr-3"
                    color="#61C4D7"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                  {{ x.pertanyaan }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  class="bg_main pa-2 rounded"
                  v-html="x.jawaban"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card>
    </div>

    <div
      v-else
      class="pa-5"
    >
      <v-card
        v-for="item in content"
        :key="item.id"
        class="rounded-max custom_card pa-5"
        style="margin-top: 20px"
      >
        <h2 class="mb-3 color_txt">
          {{ item.judul }}
        </h2>
        <p
          v-if="item.konten !== 'null'"
          class="small_txt color_txt"
          v-html="item.konten"
        />
        <div v-if="item.diagram.length">
          <v-col
            class="d-flex"
            cols="12"
            sm="3"
          >
            <v-select
              v-model="diagram"
              :items="item.diagram"
              class="ma-0 pa-0"
              return-object
              item-text="jenis"
              dense
              outlined
            />
          </v-col>
          <div
            v-if="diagram.url_file"
            class="d-flex justify-center"
          >
            <img
              :src="diagram.url_file"
              height="350px"
              alt=""
            >
          </div>
        </div>
        <div v-if="item.faq.length">
          <v-expansion-panels
            accordion
            flat
          >
            <v-expansion-panel
              v-for="(x, i) in item.faq"
              :key="i"
              class="mb-1"
            >
              <v-expansion-panel-header expand-icon="none">
                <div>
                  <v-icon
                    class="mr-3"
                    color="#61C4D7"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                  {{ x.pertanyaan }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  class="bg_main pa-2 rounded"
                  v-html="x.jawaban"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
       
      </v-card>
    </div>
    <v-overlay
      :absolute="true"
      color="white"
      opacity="1"
      :value="loading"
    >
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#00b4cc"
      />
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PageAbout",
  data() {
    return {
      loading: false,
      diagram: null,
      isMobile: false,
    };
  },

  computed: {
    ...mapState({
      content: (state) => state.tentang.tentang.filter(item => item.id !== 6),
    }),
  },
  mounted() {
    this.getData();
    this.checkMobile();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$store.dispatch("tentang/getTentang").then((data) => {
        let diagram = null;
        data.forEach((item) => {
          if (item.diagram.length) {
            diagram = item.diagram[0];
            this.diagram = diagram;
          }
        });
        this.loading = false;
      });
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 600;
    },
  },
};
</script>

<style scoped>
.app_name {
  font-size: 60px;
}
.container_about {
  padding: 20px;
}
</style>